<template>
    <OrdersList
        :pageConfig="pageConfig" />
</template>

<script>
import OrdersList from '@apps/Orders/components/OrdersList'
import pageMeta from '@/mixins/pageMeta'
export default {
    name: 'OrderPage',
    mixins: [pageMeta],
    components: {
        OrdersList
    },
    computed: {
        pageConfig() {
            return this.$route.meta?.pageConfig ? this.$route.meta.pageConfig : null
        }
    }
}
</script>